import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=c8e23c14&scoped=true"
import script from "./TheHeader.vue?vue&type=script&lang=js"
export * from "./TheHeader.vue?vue&type=script&lang=js"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=c8e23c14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-alpha.1_css-loader@6.11.0_vue-template-compiler@2.6.11_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8e23c14",
  null
  
)

export default component.exports